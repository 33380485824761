import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import URL from 'constants/url';
import CODE from 'constants/code';

//COMMON
import BFTHeader from 'components/BFTHeader';
import BFTFooter from 'components/BFTFooter';
import BFTInfoPopup from 'components/BFTInfoPopup';
import BFTError from 'components/BFTError';

import BFTMain from 'pages/main/BFTMain';
import BFTLogin from 'pages/login/BFTLogin';

//ABOUT
import BFTAboutSite from 'pages/about/BFTAboutBFT';
import BFTAboutHistory from 'pages/about/BFTAboutTeacher';
import BFTAboutOrganization from 'pages/about/BFTAboutOrganization';
import BFTAboutLocation from 'pages/about/BFTAboutLocation';

//INTRO
import BFTCurriculumTotal from 'pages/intro/BFTCurriculumTotal';
import BFTCurriculumLevel1 from 'pages/intro/BFTCurriculumLevel1';
import BFTCurriculumLevel2 from 'pages/intro/BFTCurriculumLevel2';
import BFTCurriculumLevel3 from 'pages/intro/BFTCurriculumLevel3';
import BFTCurriculumCertificate from 'pages/intro/BFTCurriculumCertificate';
import BFTCurriculumPortfolio from 'pages/intro/BFTCurriculumPortfolio';

//SUPPORT
import BFTSupportDownloadList from 'pages/support/download/BFTDownloadList';
import BFTSupportDownloadDetail from 'pages/support/download/BFTDownloadDetail';
import BFTSupportDownloadEdit from 'pages/support/download/BFTDownloadEdit';
import BFTSupportQnaList from 'pages/support/qna/BFTQnaList';
import BFTSupportQnaEdit from 'pages/support/qna/BFTQnaEdit';
import BFTSupportQnaDetail from 'pages/support/qna/BFTQnaDetail';
import BFTSupportConsult from 'pages/support/consult/BFTSupportConsult';

//INFORM
import BFTNoticeList from 'pages/inform/notice/BFTNoticeList';
import BFTNoticeDetail from 'pages/inform/notice/BFTNoticeDetail';
import BFTNoticeEdit from 'pages/inform/notice/BFTNoticeEdit';

import BFTActivityList from 'pages/inform/activity/BFTActivityList';
import BFTActivityDetail from 'pages/inform/activity/BFTActivityDetail';
import BFTActivityEdit from 'pages/inform/activity/BFTActivityEdit';

import BFTScheduleList from 'pages/inform/schedule/BFTScheduleList';
import BFTScheduleDetail from 'pages/inform/schedule/BFTScheduleDetail';
import BFTScheduleEdit from 'pages/inform/schedule/BFTScheduleEdit';
//ADMIN

import * as BFTNet from 'api/BFTFetch'; // jwt토큰 위조 검사 때문에 추가

const RootRoutes = () => {
  //useLocation객체를 이용하여 정규표현식을 사용한 /admin/~ 으로 시작하는 경로와 비교에 사용(아래 1줄) */}
  const location = useLocation();

  //리액트에서 사이트관리자에 접근하는 토큰값 위변조 방지용으로 서버에서 비교하는 함수 추가
  const jwtAuthentication = useCallback(() => {
    console.group("jwtAuthentication");
    console.log("[Start] jwtAuthentication ------------------------------");

    const jwtAuthURL = "/jwtAuthAPI";
    let requestOptions = {
      method: "POST",
    };

    BFTNet.requestFetch(jwtAuthURL, requestOptions, (resp) => {
      if (resp === false) {
        setMounted(false);
      } else {
        setMounted(true); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
      }
    });

    console.log("------------------------------jwtAuthentication [End]");
    console.groupEnd("jwtAuthentication");
  }, []);

  //시스템관리 메뉴인 /admin/으로 시작하는 URL은 모두 로그인이 필요하도록 코드추가(아래)
  const isMounted = useRef(false); // 아래 로그인 이동 부분이 2번 실행되지 않도록 즉, 마운트 될 때만 실행되도록 변수 생성
  const [mounted, setMounted] = useState(false);// 컴포넌트 최초 마운트 후 리렌더링 전 로그인 페이지로 이동하는 조건으로 사용

  useEffect(() => {
	if (!isMounted.current) { // 컴포넌트 최초 마운트 시 페이지 진입 전(렌더링 전) 실행
		isMounted.current = true; // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
		setMounted(true); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용.
		const regex = /^(\/admin\/)+(.)*$/; //정규표현식 사용: /admin/~ 으로 시작하는 경로 모두 포함
		if(regex.test(location.pathname)) {
			setMounted(false); // 이 값으로 true 일 때만 페이지를 렌더링이 되는 변수 사용. 기본은 숨기기
			jwtAuthentication(); // 이 함수에서 관리자단 인증여부 확인 후 렌더링 처리
		}
	}
  },[jwtAuthentication, location, mounted]); // location 경로와 페이지 마운트상태가 변경 될 때 업데이트 후 리렌더링

  if(mounted) { // 인증 없이 시스템관리 URL로 접근할 때 렌더링 되는 것을 방지하는 조건추가. 
	  return (
	      <Routes>
	        <Route path={URL.ERROR} element={<BFTError />} />
	        <Route path="*" element={<SecondRoutes/>} />
	      </Routes>
	  );
  }
}

const SecondRoutes = () => {
  // eslint-disable-next-line no-unused-vars
  const [loginVO, setLoginVO] = useState({});

  return (
    <>
      <BFTHeader />
      <Routes>
        {/* MAIN */}
        <Route path={URL.MAIN} element={<BFTMain />} />

        {/* LOGIN */}
        <Route path={URL.LOGIN} element={<BFTLogin
                onChangeLogin={(user) => setLoginVO(user)}
              />}/>

        {/* ERROR */}
        <Route path={URL.ERROR} element={<BFTError />} />

        {/* ABOUT */}
        <Route path={URL.ABOUT} element={<Navigate to={URL.ABOUT_BFT} />} />
        <Route path={URL.ABOUT_BFT} element={<BFTAboutSite />} />
        <Route path={URL.ABOUT_TEACHER} element={<BFTAboutHistory />} />
        {/* <Route path={URL.ABOUT_ORGANIZATION} element={<BFTAboutOrganization />} /> */}
        <Route path={URL.ABOUT_LOCATION} element={<BFTAboutLocation />} />

        {/* CURRICULUM */}
        <Route path={URL.CURRICULUM} element={<Navigate to={URL.CURRICULUM_TOTAL} />} />
        <Route path={URL.CURRICULUM_TOTAL} element={<BFTCurriculumTotal />} />
        <Route path={URL.CURRICULUM_LEVEL1} element={<BFTCurriculumLevel1 />} />
        <Route path={URL.CURRICULUM_LEVEL2} element={<BFTCurriculumLevel2 />} />
        <Route path={URL.CURRICULUM_LEVEL3} element={<BFTCurriculumLevel3 />} />
        <Route path={URL.CURRICULUM_CERTIFICATE} element={<BFTCurriculumCertificate />} />
        <Route path={URL.CURRICULUM_PORTFOLIO} element={<BFTCurriculumPortfolio />} />

        {/* SUPPORT */}
        <Route path={URL.SUPPORT} element={<Navigate to={URL.SUPPORT_DOWNLOAD} />} />
        <Route path={URL.SUPPORT_DOWNLOAD} element={<BFTSupportDownloadList />} />
        <Route path={`${URL.SUPPORT_DOWNLOAD_DETAIL}/:id`} element={<BFTSupportDownloadDetail />} />
        <Route path={URL.SUPPORT_DOWNLOAD_CREATE} element={<BFTSupportDownloadEdit mode={CODE.MODE_CREATE} />} />
        <Route path={URL.SUPPORT_DOWNLOAD_MODIFY} element={<BFTSupportDownloadEdit mode={CODE.MODE_MODIFY} />} />
        

        <Route path={URL.SUPPORT_QNA} element={<BFTSupportQnaList />} />
        <Route path={URL.SUPPORT_QNA_CREATE} element={<BFTSupportQnaEdit mode={CODE.MODE_CREATE} />} />
        <Route path={URL.SUPPORT_QNA_MODIFY} element={<BFTSupportQnaEdit mode={CODE.MODE_MODIFY} />} />
        <Route path={`${URL.SUPPORT_QNA_DETAIL}/:id`} element={<BFTSupportQnaDetail />} />

        <Route path={URL.SUPPORT_CONSULT} element={<BFTSupportConsult />} />

        {/* NEWS */}
        <Route path={URL.NEWS} element={<Navigate to={URL.NEWS_SCHEDULE} />} />

        <Route path={URL.NEWS_NOTICE} element={<BFTNoticeList />} />
        <Route path={`${URL.NEWS_NOTICE_DETAIL}/:id`} element={<BFTNoticeDetail />} />
        <Route path={URL.NEWS_NOTICE_CREATE} element={<BFTNoticeEdit mode={CODE.MODE_CREATE} />} />
        <Route path={URL.NEWS_NOTICE_MODIFY} element={<BFTNoticeEdit mode={CODE.MODE_MODIFY} />} />

        <Route path={URL.NEWS_ACTIVITY} element={<BFTActivityList />} />
        <Route path={`${URL.NEWS_ACTIVITY_DETAIL}/:id`} element={<BFTActivityDetail />} />
        <Route path={URL.NEWS_ACTIVITY_CREATE} element={<BFTActivityEdit mode={CODE.MODE_CREATE} />} />
        <Route path={URL.NEWS_ACTIVITY_MODIFY} element={<BFTActivityEdit mode={CODE.MODE_MODIFY} />} />

        <Route path={URL.NEWS_SCHEDULE} element={<BFTScheduleList />} />
        <Route path={`${URL.NEWS_SCHEDULE_DETAIL}/:id`} element={<BFTScheduleDetail />} />
        <Route path={URL.NEWS_SCHEDULE_CREATE} element={<BFTScheduleEdit mode={CODE.MODE_CREATE} />} />
        <Route path={URL.NEWS_SCHEDULE_MODIFY} element={<BFTScheduleEdit mode={CODE.MODE_MODIFY} />} />

      </Routes>
      <BFTFooter />
      <BFTInfoPopup />   
    </>
  );
}

export default RootRoutes;