import React from 'react';
import { Link } from 'react-router-dom';

import URL from 'constants/url';
import { default as BFTLeftNav } from 'components/leftmenu/BFTLeftNavAbout';

function BFTAboutTeacher() {
    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home" >Home</Link></li>
                        <li><Link to={URL.ABOUT}>학원소개</Link></li>
                        <li>강사정보</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNav></BFTLeftNav>
                    {/* <!--// Navigation --> */}
                    
                    <div className="contents SERVICE_INTRO" id="contents">
                        {/* <!-- 본문 --> */}
                        <h2 className="tit_4">원장</h2>
                        <br/>
                        <div className="msg">
                            <p>깊이 있는 학문적 연구와 현장 경험을 바탕으로 학생들에게 실질적인 지식과 현실적인 조언을 전달합니다. 다년간의 연구와 교육 경험을 통해 축적된 전문성으로 학생들의 학습 과정을 세심하게 지도하며, 미래 사회가 요구하는 창의적이고 혁신적인 인재 양성에 힘쓰고 있습니다.</p>
                            {/* <ul style={{background: "url('/assets/images/jjkim.png') no-repeat 14px 30px"}}> */}
                            <ul>
                                <li>대학교 IT 교육 경력 20년 이상</li>
                                <li>공학박사</li>
                                <li>정보처리기사/산업기사 베스트셀러 저자</li>
                                <li>SCI/SCIE/SCOPUS 국제 저명 논문지 60여편 이상</li>
                                <li>KCI 국내 저명 논문지 80여편 이상</li>
                                <li>국가 R&D 과제 다수 수행</li>
                            </ul>
                        </div>
                        
                        <br/><br/>
                        
                        <h2 className="tit_4">부원장</h2>
                        <br/>
                        <div className="msg">
                            <p>IT 분야에서 박사 학위를 취득한 전문가로, 최신 기술 동향에 밝고 심도 있는 연구를 바탕으로 학생들에게 현장감 있는 교육을 제공합니다. 실제 프로젝트 경험과 학문적 이론을 결합한 교육 방식으로, 학생들이 실제 IT 업계에서 요구하는 실무 능력을 갖출 수 있도록 돕고 있습니다. 학생들의 창의력과 문제 해결 능력을 키우는 데 중점을 두고, 개인별 맞춤 멘토링을 통해 각 학생의 잠재력을 최대한 발휘할 수 있도록 지원합니다.</p>
                            {/* <ul style={{background: "url('/assets/images/syhwang.png') no-repeat 14px 30px"}}> */}
                            <ul>
                                <li>현재 대학교 강사</li>
                                <li>공학박사</li>
                                <li>SCI/SCIE/SCOPUS 국제 저명 논문지 20여편 이상</li>
                                <li>KCI 국내 저명 논문지 20여편 이상</li>
                                <li>국가 R&D 과제 다수 수행</li>
                            </ul>
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BFTAboutTeacher;