import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as BFTNet from 'api/BFTFetch';
import URL from 'constants/url';

import BFTPaging from 'components/BFTPaging';
import { getSessionItem, setSessionItem, removeSessionItem } from 'utils/storage';
import { itemIdxByPage } from 'utils/calc';
import { default as BFTLeftNavSupport } from 'components/leftmenu/BFTLeftNavSupport';

function BFTQnaList(props) {
    console.group("BFTQnaList");
    console.log("[Start] BFTQnaList ------------------------------");
    console.log("BFTQnaList [props] : ", props);

    const sessionUser = JSON.parse(getSessionItem('userInfo'));
    const IsSessionUserStaff = sessionUser?.is_staff;

    const location = useLocation();
    console.log("BFTQnaList [location] : ", location);

	const cndRef = useRef();
    const wrdRef = useRef();

	// eslint-disable-next-line no-unused-vars
    const [searchCondition, setSearchCondition] = useState(location.state?.searchCondition || { page: 1, searchCnd: '0', searchWrd: '' });// 기존 조회에서 접근 했을 시 || 신규로 접근 했을 시
    const [paginationInfo, setPaginationInfo] = useState({});

    const [listTag, setListTag] = useState([]);

    const retrieveList = useCallback((searchCondition) => {
        console.groupCollapsed("BFTQnaList.retrieveList()");

        const retrieveListURL = '/qna/'+BFTNet.getQueryString(searchCondition);
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
            }
        }

        BFTNet.requestFetch(retrieveListURL,
            requestOptions,
            (resp) => {
                setPaginationInfo(resp.paginationInfo);

                let mutListTag = resp.length > 0 ? [] : [<p className="no_data" key="0">검색된 결과가 없습니다.</p>]; // 게시판 목록 초기값
                
                const resultCnt = parseInt(resp.count);
                const currentPageNo = resp.paginationInfo.currentPageNo;
                const pageSize = resp.paginationInfo.pageSize;

                // 리스트 항목 구성
                resp.results.forEach(function (item, index) {
                    if (index === 0) mutListTag = []; // 목록 초기화
                    const listIdx = itemIdxByPage(resultCnt , currentPageNo, pageSize, index);

                    mutListTag.push(
                        <Link
                            to={{pathname: `${URL.SUPPORT_QNA_DETAIL}/${item.id}/`}}
                            state={{
                                searchCondition: searchCondition,
                                qnaId: item.id
                            }}
                            key={item.id}
                            className="list_item" >
                            <div>{item.id}</div>
                            <div>{item.title}</div>
                            <div>
                                {
                                    new Date(item.created_at).toLocaleDateString('ko-KR', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                        })
                                }
                            </div>
                        </Link>
                    );
                });
                setListTag(mutListTag);
            },
            function (resp) {
                console.log("err response : ", resp);
            }
        );
        console.groupEnd("BFTQnaList.retrieveList()");
    },[]);

    useEffect(() => {
        retrieveList(searchCondition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("------------------------------BFTQnaList [End]");
    console.groupEnd("BFTQnaList");
    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home">Home</Link></li>
                        <li><Link to={URL.SUPPORT}>교육문의</Link></li>
                        <li>{"자주 묻는 질문(FAQ)"}</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavSupport></BFTLeftNavSupport>
                    {/* <!--// Navigation --> */}

                    <div className="contents NOTICE_LIST" id="contents">
                        {/* <!-- 본문 --> */}

                        <div className="top_tit">
                            <h1 className="tit_1">교육문의</h1>
                        </div>

                        <h2 className="tit_2">{"자주 묻는 질문(FAQ)"}</h2>

                        {/* <!-- 검색조건 --> */}
                        <div className="condition">
                            <ul>
                                <li className="third_1 L">
                                    <label className="f_select" htmlFor="sel1">
                                        <select id="sel1" title="조건" defaultValue={searchCondition.searchCnd} ref={cndRef}
                                            onChange={e => {
                                                cndRef.current.value = e.target.value; 
                                            }}
                                        >
                                            <option value="0">제목</option>
                                            <option value="1">내용</option>
                                        </select>
                                    </label>
                                </li>
                                <li className="third_2 R">
                                    <span className="f_search w_500">
                                        <input type="text" name="" defaultValue={searchCondition.searchWrd} placeholder="" ref={wrdRef}
                                            onChange={e => {
                                                wrdRef.current.value = e.target.value;
                                            }}
                                        />
                                        <button type="button"
                                            onClick={() => {
                                                retrieveList({ ...searchCondition, page: 1, searchCnd: cndRef.current.value, searchWrd: wrdRef.current.value });
                                            }}>조회</button>
                                    </span>
                                </li>
                                {IsSessionUserStaff &&
                                    <li>
                                        <Link to={URL.SUPPORT_QNA_CREATE} state={{searchCondition: searchCondition}} className="btn btn_blue_h46 pd35">등록</Link>
                                    </li>
                                }
                            </ul>
                        </div>
                        {/* <!--// 검색조건 --> */}

                        {/* <!-- 게시판목록 --> */}
                        <div className="board_list BRD002">
                            <div className="head">
                                <span>번호</span>
                                <span>제목</span>
                                <span>작성일</span>
                            </div>
                            <div className="result">
                                {listTag}
                            </div>
                        </div>
                        {/* <!--// 게시판목록 --> */}

                        <div className="board_bot">
                            {/* <!-- Paging --> */}
                            <BFTPaging pagination={paginationInfo} moveToPage={passedPage => {
                                retrieveList({ ...searchCondition, page: passedPage, searchCnd: cndRef.current.value, searchWrd: wrdRef.current.value })
                            }} />
                            {/* <!--/ Paging --> */}
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BFTQnaList;