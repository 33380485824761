import RootRoutes from './routes';
import React from 'react';

import 'css/base.css';
import 'css/layout.css';
import 'css/component.css';
import 'css/page.css';
import 'css/response.css';
// 이것은 Slick 라이브러리의 기본 스타일입니다.
import "slick-carousel/slick/slick.css";
// 이것은 Slick 라이브러리의 테마 스타일입니다(옵션).
import "slick-carousel/slick/slick-theme.css";


function App() {

  return (
    <div className="wrap">
      <React.StrictMode>
        <RootRoutes />
      </React.StrictMode>
    </div>
  )
}

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.REACT_APP_EGOV_CONTEXT_URL", process.env.REACT_APP_EGOV_CONTEXT_URL);

export default App;
