import React from 'react';
import { Link } from 'react-router-dom';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';
import URL from 'constants/url';

function BFTCurriculumPortfolio() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><Link className="home" to={URL.MAIN}>Home</Link></li>
                    <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                    <li>포트폴리오</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">커리큘럼</h1>

                    <p className="txt_1"></p>
                    
                    <h2 className="tit_4">포트폴리오</h2>

                    {/* <img className="w" src="/assets/images/portfolio.png" alt="포트폴리오 이미지"/> */}
                    {/* <br /><br /> */}
                    
                    <h3 className="tit_5">프로젝트</h3>
                    <div className="msg">
                        <p>프로젝트 개발</p>
                        <ul className="curri">
                            <li>-----</li>
                            <li>-----</li>
                            <li>-----</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">논문투고</h3>
                    <div className="msg">
                        <p>논문투고</p>
                        <ul className="curri">
                            <li>-----</li>
                            <li>-----</li>
                            <li>-----</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">공모전 참여</h3>
                    <div className="msg">
                        <p>공모전 참여</p>
                        <ul className="curri">
                            <li>-----</li>
                            <li>-----</li>
                            <li>-----</li>
                        </ul>
                    </div>

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default BFTCurriculumPortfolio;