import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as BFTNet from 'api/BFTFetch';
import URL from 'constants/url';
import { default as BFTCarouselComponent } from 'components/BFTCarouselComponent';

function BFTMain(props) {
    console.group("BFTMain");
    console.log("[Start] BFTMain ------------------------------");
    console.log("BFTMain [props] : ", props);

    const location = useLocation();
    console.log("BFTMain [location] : ", location);

	// eslint-disable-next-line no-unused-vars
    const [noticeBoard, setNoticeBoard] = useState([]);
	// eslint-disable-next-line no-unused-vars
    const [activityBoard, setActivityBoard] = useState([]);
    const [noticeListTag, setNoticeListTag] = useState(null);
    const [activityListTag, setActivityListTag] = useState(null);
    const [activeTab, setActiveTab] = useState('notice');

    // 탭 변경 핸들러
    const changeTab = (newTab) => {
        setActiveTab(newTab);
    };
    const retrieveList = useCallback(() => {
        console.groupCollapsed("BFTMain.retrieveList()");
        const retrieveNoticeListURL = '/notice/';
        const retrieveActivityListURL = '/activity/';
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-type': 'application/json'
            }
        };
    
        BFTNet.requestFetch(retrieveNoticeListURL,
            requestOptions,
            (resp) => {
                // 백엔드에서 받은 데이터를 직접 사용
                if (resp && resp.results.length > 0) {
                    // 첫 6개 항목만 가져옵니다.
                    const firstSixItems = resp.results.slice(0, 6);
        
                    const mutNotiListTag = firstSixItems.map((item) => (
                        <li>
                            <Link
                                to={{ pathname: `${URL.NEWS_NOTICE_DETAIL}/${item.id}/` }}
                                state={{ noticeId: item.id }}
                                key={item.id}
                            >
                                {item.title} {/* title을 사용 */}
                                <span>
                                {
                                    new Date(item.created_at).toLocaleDateString('ko-KR', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                    })
                                }
                                </span> {/* created_at을 사용 */}
                            </Link>
                        </li>
                    ));
                    setNoticeListTag(mutNotiListTag);
                } else {
                    // 검색된 결과가 없을 때의 처리
                    setNoticeListTag([<li key="0">검색된 결과가 없습니다.</li>]);
                }
            },
            function (error) {
                console.log("err response : ", error);
            }
        );

        BFTNet.requestFetch(retrieveActivityListURL,
            requestOptions,
            (resp) => {
                // 백엔드에서 받은 데이터를 직접 사용
                if (resp && resp.results.length > 0) {
                    // 첫 6개 항목만 가져옵니다.
                    const firstSixItems = resp.results.slice(0, 6);
        
                    const mutActiListTag = firstSixItems.map((item) => (
                        <li>
                            <Link
                                to={{ pathname: `${URL.NEWS_ACTIVITY_DETAIL}/${item.id}/` }}
                                state={{ activityId: item.id }}
                                key={item.id}
                            >
                                {item.title} {/* title을 사용 */}
                                <span>
                                {
                                    new Date(item.created_at).toLocaleDateString('ko-KR', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                    })
                                }
                                </span> {/* created_at을 사용 */}
                            </Link>
                        </li>
                    ));
                    setActivityListTag(mutActiListTag);
                } else {
                    // 검색된 결과가 없을 때의 처리
                    setActivityListTag([<li key="0">검색된 결과가 없습니다.</li>]);
                }
            },
            function (error) {
                console.log("err response : ", error);
            }
        );
        
        console.groupEnd("BFTMain.retrieveList()");
    }, []);
    

    useEffect(() => {
        retrieveList();
    }, [retrieveList]);

    console.log("------------------------------BFTMain [End]");
    console.groupEnd("BFTMain");

    return (
        <div className="container P_MAIN">
            <div className="c_wrap">
                <div className="colbox">
                    {/* <div className="left_col">
                        <img src="/assets/images/bftlab_logo_main.jpg" alt="비에프티랩 코딩학원" />
                    </div> */}
                    <BFTCarouselComponent />

                    <div className="right_col">
                        <div className="mini_board">
                            <ul className="tab">
                                <li><Link to="#" className={activeTab === 'notice' ? 'on' : ''} onClick={() => changeTab('notice')}>공지사항</Link></li>
                                <li><Link to="#" className={activeTab === 'activity' ? 'on' : ''} onClick={() => changeTab('activity')}>교육활동</Link></li>
                            </ul>
                            {/* 공지사항/교육활동 폰트 사이즈: .P_MAIN .mini_board .tab li a */}
                            <div className="list">
                                {activeTab === 'notice' && (
                                    <div className="notice">
                                        <h2 className="blind">공지사항</h2>
                                        <ul>{noticeListTag}</ul>
                                        <Link to={URL.NEWS_NOTICE} className="more">더보기</Link>
                                    </div>
                                )}

                                {activeTab === 'activity' && (
                                    <div className="activity">
                                        <h2 className="blind">교육활동</h2>
                                        <ul>{activityListTag}</ul>
                                        <Link to={URL.NEWS_ACTIVITY} className="more">더보기</Link>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="banner">
                            <Link to={URL.SUPPORT_DOWNLOAD} className="bn1">
                                <strong>자료실</strong>
                                <span>다양한 자료를<br />다운로드 받으실 수 있습니다.</span>
                            </Link>
                            <Link to={URL.SUPPORT_QNA} className="bn2">
                                <strong>자주 묻는 질문(FAQ)</strong>
                                <span>자주 묻는 질문과 답변을 제공합니다.</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="banner_bot">
                    <div className="b1">
                        <div>
                            <h2>학원 소개</h2>
                            <p>비에프티랩 코딩학원을 소개합니다.</p>
                        </div>
                        <Link to={URL.ABOUT_BFT}>자세히 보기</Link>
                    </div>
                    <div className="b2">
                        <div>
                            <h2>커리큘럼</h2>
                            <p>비에프티랩 코딩학원에서 제공하는 커리큘럼입니다.</p>
                        </div>
                        <Link to={URL.CURRICULUM}>자세히 보기</Link>
                    </div>
                    <div className="b3">
                        <div>
                            <h2>상담신청</h2>
                            <p>학원 등록 및 교육 과정에 대한 상담을 신청하실 수 있습니다.</p>
                        </div>
                        <Link to={URL.SUPPORT_CONSULT}>자세히 보기</Link>
                    </div>
                    <div className="b4">
                        <div>
                            <h2>주요 일정</h2>
                            <p>교육, 자격증시험 등 주요 일정을 조회하실 수 있습니다.</p>
                        </div>
                        <Link to={URL.NEWS}>자세히 보기</Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default BFTMain;