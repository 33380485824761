import React, { useState, useEffect } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import * as BFTNet from 'api/BFTFetch';
import URL from 'constants/url';
import CODE from 'constants/code';

import { default as BFTLeftNavSupport } from 'components/leftmenu/BFTLeftNavSupport';
import BFTAttachFile from 'components/BFTAttachFile';
import bbsFormValidator from 'utils/bbsFormValidator';

function BFTQnaEdit(props) {
    console.group("BFTQnaEdit");
    console.log("------------------------------");
    console.log("BFTQnaEdit [props] : ", props);

    const navigate = useNavigate();
    const location = useLocation();
    console.log("BFTQnaEdit [location] : ", location);
    const qnaId = location.state?.qnaId;
    const [modeInfo, setModeInfo] = useState({ mode: props.mode });
    const [boardDetail, setBoardDetail] = useState({});
    const [boardAttachFiles, setBoardAttachFiles] = useState();

    const initMode = () => {
        switch (props.mode) {
            case CODE.MODE_CREATE:
                setModeInfo({
                    ...modeInfo,
                    modeTitle: "등록",
                    method: "POST",
                    editURL: '/qna/create'
                });
                break;
            case CODE.MODE_MODIFY:
                setModeInfo({
                    ...modeInfo,
                    modeTitle: "수정",
                    method: "PUT",
                    editURL: `/qna/${qnaId}/`
                });
                retrieveDetail();
                break;
			default:
                navigate({pathname: URL.ERROR}, {state: {msg : ""}});
        }
        
    }

    const retrieveDetail = () => {
        const retrieveDetailURL = `/qna/${qnaId}/`;
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-type': 'application/json'
            }
        }
        BFTNet.requestFetch(retrieveDetailURL,
            requestOptions,
            function (resp) {
                if (modeInfo.mode === CODE.MODE_MODIFY) {
                    setBoardDetail(resp);
                    setBoardAttachFiles(resp.resultFiles);
                }
            }
        );
    }
	
    const updateBoard = () => {
        const formData = new FormData();
        for (let key in boardDetail) {
            formData.append(key, boardDetail[key]);
            //console.log("boardDetail [%s] ", key, boardDetail[key]);
        }

        if (bbsFormValidator(formData, 'qna')) {
            const requestOptions = {
                method: modeInfo.method,
                body: formData
            }
    
            BFTNet.requestFetch(modeInfo.editURL,
                requestOptions,
                (resp) => {
                    if (Number(resp.status) === Number(CODE.RCV_SUCCESS_CREATE)) {
                        alert('게시글이 저장되었습니다.')
                        navigate(URL.SUPPORT_QNA, {state:{qnaId : qnaId}});
                    } else {
                        // alert("ERR : " + resp.message);
                        navigate({pathname: URL.ERROR}, {state: {msg : resp.resultMessage}});
                    }
                }
            );
        };
    };

	const Location = React.memo(function Location(masterBoard) {
        return (
            <div className="location">
                <ul>
                    <li><Link to={URL.MAIN} className="home">Home</Link></li>
                    <li><Link to={URL.SUPPORT}>교육문의</Link></li>
                    <li>{"자주 묻는 질문(FAQ)"}</li>
                </ul>
            </div>
        )
    });

    useEffect(function () {
        initMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.groupEnd("BFTQnaEdit");

    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <Location />
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavSupport></BFTLeftNavSupport>
                    {/* <!--// Navigation --> */}

                    <div className="contents NOTICE_LIST" id="contents">
                        {/* <!-- 본문 --> */}

                        <div className="top_tit">
                            <h1 className="tit_1">자주 묻는 질문(FAQ)</h1>
                        </div>
                        
                        <h2 className="tit_2">{modeInfo.modeTitle}</h2>

                        <div className="board_view2">
                            <dl>
                                <dt>
                                    <label htmlFor="qnaTitle">제목<span className="req">필수</span></label>
                                </dt>
                                <dd>
                                    <input className="f_input2 w_full" id="qnaTitle" name="qnaTitle" type="text"
                                        defaultValue={boardDetail.title}
                                        onChange={e => setBoardDetail({ ...boardDetail, qnaTitle: e.target.value })}
                                        maxLength="60" />
                                </dd>
                            </dl>
                            <dl>
                                <dt><label htmlFor="qnaQ">질문<span className="req">필수</span></label></dt>
                                <dd>
                                    <textarea className="f_txtar w_full h_200" id="qnaQ" name="qnaQ" cols="30" rows="10" placeholder=""
                                        defaultValue={boardDetail.question}
                                        onChange={e => setBoardDetail({ ...boardDetail, qnaQ: e.target.value })}></textarea>
                                </dd>
                            </dl>
                            <dl>
                                <dt><label htmlFor="qnaA">답변<span className="req">필수</span></label></dt>
                                <dd>
                                    <textarea className="f_txtar w_full h_200" id="qnaA" name="qnaA" cols="30" rows="10" placeholder=""
                                        defaultValue={boardDetail.answer}
                                        onChange={e => setBoardDetail({ ...boardDetail, qnaA: e.target.value })}></textarea>
                                </dd>
                            </dl>
                            {/* 답글이 아니고 게시판 파일 첨부 가능 상태에서만 첨부파일 컴포넌트 노출 */}
                            <BFTAttachFile
                                fnChangeFile={(attachfile) => {
                                    console.log("====>>> Changed attachfile file = ", attachfile);
                                    const arrayConcat = { ...boardDetail}; // 기존 단일 파일 업로드에서 다중파일 객체 추가로 변환(아래 for문으로)
                                    for ( let i = 0; i < attachfile.length; i++) {
                                        arrayConcat[`file_${i}`] = attachfile[i];
                                    }
                                    setBoardDetail(arrayConcat);
                                }}
                                fnDeleteFile={(deletedFile) => {
                                    console.log("====>>> Delete deletedFile = ", deletedFile);
                                    setBoardAttachFiles(deletedFile);
                                }}
                                boardFiles={boardAttachFiles}
                                mode={props.mode} 
                            />
                            {/* <!-- 버튼영역 --> */}
                            <div className="board_btn_area">
                                <div className="left_col btn1">
                                    <button className="btn btn_skyblue_h46 w_100"
                                        onClick={() => updateBoard()}>저장</button>
                                </div>

                                <div className="right_col btn1">
                                    <Link to={URL.SUPPORT_QNA} className="btn btn_blue_h46 w_100">목록</Link>
                                </div>
                            </div>
                            {/* <!--// 버튼영역 --> */}
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default BFTQnaEdit;