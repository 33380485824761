import React, {useState} from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import * as BFTNet from 'api/BFTFetch';

import URL from 'constants/url';
import CODE from 'constants/code';
import { getSessionItem, setSessionItem, removeSessionItem } from 'utils/storage';
import BFTInfoPopup from './BFTInfoPopup'; // BFTInfoPopup 컴포넌트 import

function BFTHeader() {
    console.group("BFTHeader");
    console.log("[Start] BFTHeader ------------------------------");
    const [isMenuOpen, setIsMenuOpen] = useState(false); // 메뉴 상태 관리
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // 모바일 메뉴 상태 관리
    const [openSubMenus, setOpenSubMenus] = useState({}); // 모바일 서브메뉴 상태 관리
    const [showInfoPopup, setShowInfoPopup] = useState(false); // 팝업 표시 상태 관리

    const sessionUserToken = getSessionItem('accessToken');
    const sessionUser = JSON.parse(getSessionItem('userInfo'));
    const sessionUserName = sessionUser?.username;
    const IsSessionUserStaff = sessionUser?.is_staff;

    const navigate = useNavigate();

    const logInHandler = () => { // 로그인 정보 없을 시
        navigate(URL.LOGIN);
        toggleMobileMenu();
    }

    const logOutHandler = () => {// 로그인 정보 존재할 때
        removeSessionItem('accessToken'); // 또는 세션 항목을 완전히 제거하는 함수를 사용
        removeSessionItem('refreshToken'); // refreshToken 제거
        removeSessionItem('userInfo'); // userInfo 제거
        window.alert("로그아웃되었습니다!");
        navigate(URL.MAIN);
    }

    // 웹 메뉴 토글 함수
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // 모바일 메뉴 토글 함수
    const toggleMobileMenu = () => {
        if (isMobileMenuOpen) {
            // 모바일 메뉴가 열려 있을 때만 서브 메뉴 상태 초기화
            setOpenSubMenus({});
        }
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // 모바일 서브 메뉴 토글 함수
    const toggleMobileSubMenu = (menuName) => {
        setOpenSubMenus((prevOpenSubMenus) => ({
          ...prevOpenSubMenus,
          [menuName]: !prevOpenSubMenus[menuName], // 현재 상태를 반전
        }));
      };

    //popup 토글 함수
    const toggleInfoPopup = () => {
        setShowInfoPopup(!showInfoPopup); // 팝업 표시 상태 토글
    };

    console.log("------------------------------BFTHeader [End]");
    console.groupEnd("BFTHeader");

    return (
        // <!-- header -->
        
        <div className="header">
            <div className="inner">
                {/* <button className="ico lnk_go_template" onClick={toggleInfoPopup}>사이트 소개</button> */}
                
                {/* 템플릿 소개 팝업 */}
                <BFTInfoPopup onClose={toggleInfoPopup} show={showInfoPopup} />
                
                <h1 className="logo">
                    <Link to={URL.MAIN} className="w"><img src="/assets/images/logo_header_w2.png" alt="비에프티랩 코딩학원" /></Link>
                    <Link to={URL.MAIN} className="m"><img src="/assets/images/logo_header_m2.png" alt="비에프티랩 코딩학원" /></Link>
                </h1>

                <div className="gnb">
                    <h2 className="blind">주메뉴</h2>
                    <ul>
                        <li><NavLink to={URL.ABOUT} className={({ isActive }) => (isActive ? "cur" : "")}>학원소개</NavLink></li>
                        <li><NavLink to={URL.CURRICULUM} className={({ isActive }) => (isActive ? "cur" : "")}>커리큘럼</NavLink></li>
                        <li><NavLink to={URL.SUPPORT} className={({ isActive }) => (isActive ? "cur" : "")}>교육문의</NavLink></li>
                        <li><NavLink to={URL.NEWS} className={({ isActive }) => (isActive ? "cur" : "")}>소식</NavLink></li>
                    </ul>
                </div>

                {/* <!-- PC web에서 보여지는 영역 --> */}

                {/* <div className="user_info"> */}
                    {/* 로그아웃 : 로그인 정보 있을때 */}
                    {/* {sessionUserToken &&
                        <>
                            <span className="person">{sessionUserName} </span> 님이, 관리자로 로그인하셨습니다.
                            <button onClick={logOutHandler} className="btn">로그아웃</button>
                        </>
                    } */}
                    {/* 로그인 : 로그인 정보 없을 때 */}
                    {/* {!sessionUserToken &&
                        <button onClick={logInHandler} className="btn login">로그인</button>
                    }
                </div> */}

                {/* <!--// PC web에서 보여지는 영역 --> */}

                {/* <!-- right area --> */}
                <div className="right_a">
                    <button type="button" className={`btn btnAllMenu ${isMenuOpen ? 'active' : ''}`} title={isMenuOpen ? "전체메뉴 열림" : "전체메뉴 닫힘"} onClick={toggleMenu}>전체메뉴</button>
                    <button type="button" className={`btn mobile btnAllMenuM ${isMobileMenuOpen ? 'active' : ''}`} title={isMobileMenuOpen ? "전체메뉴 열림" : "전체메뉴 닫힘"} onClick={toggleMobileMenu}>전체메뉴</button>
                </div>
            </div>

            {/* <!-- All menu : web --> */}
       
            <div className={`all_menu WEB ${isMenuOpen ? '' : 'closed'}`}>
            <h2 className="blind">전체메뉴</h2>
                <div className="inner">
                    <div className="col">
                        <h3>학원소개</h3>
                        <ul>
                            <li><NavLink to={URL.ABOUT_BFT} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_TEACHER} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>강사정보</NavLink></li>
                            <li><NavLink to={URL.ABOUT_LOCATION} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>찾아오시는 길</NavLink></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>커리큘럼</h3>
                        <ul>
                            <li><NavLink to={URL.CURRICULUM_TOTAL} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>전체 교육과정</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL1} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>초급 레벨</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL2} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>중급 레벨</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL3} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>고급 레벨</NavLink></li>
                            {/* <li><NavLink to={URL.CURRICULUM_CERTIFICATE} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>자격증</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_PORTFOLIO} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>포트폴리오</NavLink></li> */}
                        </ul>
                    </div>
                    <div className="col">
                        <h3>교육문의</h3>
                        <ul>
                            <li><NavLink to={URL.SUPPORT_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>자료실</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>자주 묻는 질문(FAQ)</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_CONSULT} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>상담신청</NavLink></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>소식</h3>
                        <ul>
                            <li><NavLink to={URL.NEWS_SCHEDULE} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>주요일정</NavLink></li>
                            <li><NavLink to={URL.NEWS_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>공지사항</NavLink></li>
                            <li><NavLink to={URL.NEWS_ACTIVITY} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMenu}>교육활동</NavLink></li>
                        </ul>
                    </div>
                    </div>
                </div>
          
            
            {/* <!-- All menu : mobile --> */}
            
            <div className={`all_menu Mobile ${isMobileMenuOpen ? '' : 'closed'}`}>
                <div className="user_info_m">
                    {/* 로그아웃 : 로그인 정보 있을때 */}
                    {/* {sessionUserToken &&
                        <>
                            <span className="person">{sessionUserName} </span>이 로그인하셨습니다.
                            <button onClick={logOutHandler} className="btn logout">로그아웃</button>
                        </>
                    } */}

                    {/* 로그인 : 로그인 정보 없을 때 */}
                    {/* {!sessionUserToken &&
                        <button onClick={logInHandler} className="btn login">로그인</button>
                    } */}
                    <button className="btn noscript close" type="button" onClick={toggleMobileMenu}>전체메뉴 닫기</button>
                </div>
                <div className="menu">
                    <h3><Link to='#' onClick={() => toggleMobileSubMenu('introduction')} className={`${openSubMenus['introduction'] ? 'active' : ''}`}>학원소개</Link></h3>
                    <div className={`submenu ${!openSubMenus['introduction'] ? 'closed' : ''}`} style={{ height: openSubMenus['introduction'] ? '144px' : '' }}> 
                        <ul>
                            <li><NavLink to={URL.ABOUT_BFT} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_TEACHER} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>강사정보</NavLink></li>
                            {/* <li><NavLink to={URL.ABOUT_ORGANIZATION} className={({ isActive }) => (isActive ? "cur" : "")}>조직소개</NavLink></li> */}
                            <li><NavLink to={URL.ABOUT_LOCATION} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>찾아오시는 길</NavLink></li>
                        </ul>
                    </div>
                    <h3><Link to='#' onClick={() => toggleMobileSubMenu('curri')} className={`${openSubMenus['curri'] ? 'active' : ''}`}>커리큘럼</Link></h3>
                    <div className={`submenu ${!openSubMenus['curri'] ? 'closed' : ''}`} style={{ height: openSubMenus['curri'] ? '192px' : '' }}>
                        <ul>
                            <li><NavLink to={URL.CURRICULUM_TOTAL} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>전체 교육과정</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL1} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>초급 레벨</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL2} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>중급 레벨</NavLink></li>
                            <li><NavLink to={URL.CURRICULUM_LEVEL3} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>고급 레벨</NavLink></li>
                        </ul>
                    </div>
                    <h3><Link to='#' onClick={() => toggleMobileSubMenu('support')} className={`${openSubMenus['support'] ? 'active' : ''}`}>교육문의</Link></h3>
                    <div className={`submenu ${!openSubMenus['support'] ? 'closed' : ''}`} style={{ height: openSubMenus['support'] ? '144px' : '' }}>
                        <ul>
                            <li><NavLink to={URL.SUPPORT_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>자료실</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_QNA} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>자주 묻는 질문(FAQ)</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_CONSULT} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>상담신청</NavLink></li>
                        </ul>
                    </div>
                    <h3><Link to='#' onClick={() => toggleMobileSubMenu('infrom')} className={`${openSubMenus['infrom'] ? 'active' : ''}`}>소식</Link></h3>
                    <div className={`submenu ${!openSubMenus['infrom'] ? 'closed' : ''}`} style={{ height: openSubMenus['infrom'] ? '144px' : '' }}>
                        <ul>
                            <li><NavLink to={URL.NEWS_SCHEDULE} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>주요일정</NavLink></li>
                            <li><NavLink to={URL.NEWS_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>공지사항</NavLink></li>
                            <li><NavLink to={URL.NEWS_ACTIVITY} className={({ isActive }) => (isActive ? "cur" : "")} onClick={toggleMobileMenu}>교육활동</NavLink></li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            {/* <!--// All menu --> */}
        </div>
        
        // <!--// header -->
    );
}

export default BFTHeader;