import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { default as BFTLeftNavSupport } from 'components/leftmenu/BFTLeftNavSupport';
import * as BFTNet from 'api/BFTFetch';
import URL from 'constants/url';
import CODE from 'constants/code';


function BFTSupportConsult() {
    console.group("BFTSupportConsult");
    console.log("[Start] BFTSupportConsult ------------------------------");

    const navigate = useNavigate();
    const location = useLocation();
	const checkRef = useRef([]);
	
    console.log("BFTSupportConsult [location] : ", location);

    const [consultDetail, setConsultDetail] = useState({});


	const formValidator = (formData) => {
        if (formData.get('studentName') === null || formData.get('studentName') === "") {
            alert("학생명을 입력해주세요.");
            return false;
        }
        if (formData.get('consultContent') === null || formData.get('consultContent') === "") {
            alert("상담내용을 입력해주세요.");
            return false;
        }
        if (formData.get('contact') === null || formData.get('contact') === "") {
            alert("연락처를 입력해주세요.");
            return false;
        }
        return true;
    };

    const updateBoard = () => {
        let requestOptions ={};
        const formData = new FormData();

            for (let key in consultDetail) {
                formData.append(key, consultDetail[key]);
                //console.log("consultDetail [%s] ", key, consultDetail[key]);
            }

            if (formValidator(formData)) {
                const consultURL = '/consult_email/';
                requestOptions = {
                    method: 'POST',
                    body: formData
                }

                BFTNet.requestFetch(consultURL,
                    requestOptions,
                    (resp) => {
                        if (Number(resp.status) === Number(CODE.RCV_SUCCESS)) {
                            navigate({ pathname: URL.MAIN });
                            alert('상담 신청되었습니다. 감사합니다.');
                        } else {
                            navigate({pathname: URL.ERROR}, {state: {msg : resp.error}});
                        }
                    }
                );
            };
        };

    useEffect(() => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("------------------------------BFTSupportConsult [End]");
    console.groupEnd("BFTSupportConsult");

    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home">Home</Link></li>
                        <li><Link to={URL.SUPPORT}>교육문의</Link></li>
                        <li>상담신청</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavSupport></BFTLeftNavSupport>
                    {/* <!--// Navigation --> */}

                    <div className="contents BOARD_CREATE_REG" id="contents">
                        {/* <!-- 본문 --> */}

                        <div className="top_tit">
                            <h1 className="tit_1">교육문의</h1>
                        </div>

                        <h2 className="tit_2">상담신청서 작성</h2>

                        <br />
                        <p className="msg_1">
                            아래 양식에 맞추어 신청해주시면 빠른 시간 내 연락드리겠습니다.<br/>
                        </p>
                        <br />
                        <div className="board_view2">
                            <dl>
                                <dt><label htmlFor="studentName">학생 이름</label><span className="req">필수</span></dt>
                                <dd>
                                    <input className="f_input2 w_full" type="text" name="studentName" title="" id="studentName" placeholder="학생 이름을 작성해주세요."
                                        defaultValue=""
                                        onChange={e => setConsultDetail({ ...consultDetail, studentName: e.target.value })}
										ref={el => (checkRef.current[0] = el)}
                                    />
                                </dd>
                            </dl>
                            <dl>
                                <dt><label htmlFor="consultContent">상담내용</label><span className="req">필수</span></dt>
                                <dd>
                                    <textarea className="f_txtar w_full h_100" name="consultContent" id="consultContent" cols="30" rows="10" placeholder="궁금하신 사항이나 상담하고 싶으신 내용을 작성해주세요."
                                        defaultValue=""
                                        onChange={e => setConsultDetail({ ...consultDetail, consultContent: e.target.value })}
										ref={el => (checkRef.current[1] = el)}
                                    ></textarea>
                                </dd>
                            </dl>
                            <dl>
                                <dt><label htmlFor="contact">연락처</label><span className="req">필수</span></dt>
                                <dd>
                                    <input className="f_input2 w_full" type="text" name="contact" title="" id="contact" placeholder="전화번호 또는 이메일을 입력해주세요."
                                        defaultValue=""
                                        onChange={e => setConsultDetail({ ...consultDetail, contact: e.target.value })}
										ref={el => (checkRef.current[0] = el)}
                                    />
                                </dd>
                            </dl>

                            {/* <!-- 버튼영역 --> */}
                            <div className="board_btn_area">
                                <div className="right_col btn1">
                                    <button className="btn btn_skyblue_h46 w_100"
                                        onClick={() => updateBoard()}>신청</button>
                                </div>

                            </div>
                            {/* <!--// 버튼영역 --> */}
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default BFTSupportConsult;