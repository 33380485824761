import React from 'react';
import { Link } from 'react-router-dom';

import URL from 'constants/url';
import { default as BFTLeftNav } from 'components/leftmenu/BFTLeftNavAbout';

function BFTAboutLocation() {
    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home" >Home</Link></li>
                        <li><Link to={URL.ABOUT}>학원소개</Link></li>
                        <li>찾아오시는 길</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNav></BFTLeftNav>
                    {/* <!--// Navigation --> */}

                    <div className="contents SITE_CONTACT_US" id="contents">
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">찾아오시는 길</h1>

                        <p className="txt_1">
                            안양시 동안구 평촌학원가에 위치하고 있습니다.
                        </p>

                        <h2 className="tit_4">지도</h2>

                        <div className="map">
                            <a href="https://naver.me/5N1lEFmv" target="_blank" rel="noreferrer">
                                <img src="/assets/images/map.png" alt="" />
                            </a>
                            
                        </div>

                        <div className="addr">
                            <div className="left_col">
                                <h3>비에프티랩 코딩학원 주소</h3>
                                <dl>
                                    <dt>도로명주소</dt>
                                    <dd>(14103) 경기도 안양시 동안구 귀인로 204 마루빌딩 6층</dd>
                                </dl>
                                <dl>
                                    <dt>지번주소</dt>
                                    <dd>(14103) 경기도 안양시 동안구 평촌동 343 마루빌딩 6층</dd>
                                </dl>
                            </div>
                            <div className="right_col">
                                <h3>QR코드로 위치알아보기</h3>
                                <p>스마트폰에서 QR코드<br />
                                    리더를 이용해 사진·<br />
                                    지도 등 다양한 정보를<br />
                                    확인하세요.</p>
                                <img className="qr" src="/assets/images/map_QR.png" alt="qr code" />
                            </div>
                        </div>

                        <div className="way">
                            <div className="left_col">
                                <h3>대중교통</h3>
                                <dl>
                                    <dt>지하철 4호선</dt>
                                    <dd>평촌역 대중교통 9분, 자동차 5분, 도보 20분거리</dd>
                                </dl>
                                <dl>
                                    <dt>지하철 4호선</dt>
                                    <dd>범계역 대중교통 9분, 자동차 5분, 도보 20분거리</dd>
                                </dl>
                            </div>
                            <div className="right_col">
                                <h3>연락처</h3>
                                <dl>
                                    <dt className="call">전화</dt>
                                    <dd>010-7714-3281</dd>
                                </dl>
                                <dl>
                                    <dt className="email">이메일</dt>
                                    <dd>bftlab@bftlab.co.kr</dd>
                                </dl>
                            </div>
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BFTAboutLocation;