import React from 'react';
import { Link } from 'react-router-dom';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';
import URL from 'constants/url';

function BFTCurriculumCertificate() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><Link className="home" to={URL.MAIN}>Home</Link></li>
                    <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                    <li>자격증</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">커리큘럼</h1>

                    <p className="txt_1"></p>
                    
                    <h2 className="tit_4">자격증</h2>

                    <h3 className="tit_5">컴퓨팅사고활용능력(CTAA)</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>1급</li>
                            <li>2급</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">COS(Coding Specialist)</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>1급</li>
                            <li>2급</li>
                            <li>3급</li>
                            <li>4급</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">COS PRO(Coding Specialist Pro)</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>1급</li>
                            <li>2급</li>
                            <li>3급</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">사무자동화</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>ITQ</li>
                            <li>GTQ</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">컴퓨터활용능력</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>1급</li>
                            <li>2급</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">워드프로세서</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>단일급</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">정보처리</h3>
                    <div className="msg">
                        <p>자격증 설명</p>
                        <ul className="curri">
                            <li>기능사</li>
                            <li>산업기사</li>
                            <li>기사</li>
                        </ul>
                    </div>                                                                                                    

                    {/* <img className="w" src="/assets/images/certificate.png" alt=""/> */}

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default BFTCurriculumCertificate;