const bbsFormValidator = (formData, type) => {
    // 공지글 검사용 필드
    const noticeFields = [
        { key: 'noticeTitle', name: '공지 제목' },
        { key: 'noticeContent', name: '공지 내용' }
    ];
    // 활동글 검사용 필드
    const activityFields = [
        { key: 'activityTitle', name: '활동 제목' },
        { key: 'activityContent', name: '활동 내용' }
    ];

    const downloadFields = [
        { key: 'noticeTitle', name: '자료실 제목' },
        { key: 'noticeContent', name: '자료실 내용' }
    ];

    const qnaFields = [
        { key: 'qnaTitle', name: 'QNA 제목' },
        { key: 'qnaQ', name: 'QNA 질문' },
        { key: 'qnaA', name: 'QNA 답변' }
    ];

    // 선택된 타입에 따라 검사할 필드 결정
    let fieldsToValidate
    switch (type) {
        case 'notice':
            fieldsToValidate = noticeFields;
            break;
        case 'activity':
            fieldsToValidate = activityFields;
            break;
        case 'qna':
            fieldsToValidate = qnaFields;
            break;
        case 'download':
            fieldsToValidate = downloadFields;
        default:
            fieldsToValidate = [];
    }
    // 필드 검사 로직
    for (let field of fieldsToValidate) {
        if (!formData.get(field.key) || formData.get(field.key).trim() === "") {
            alert(`${field.name}은(는) 필수 값입니다.`);
            return false;
        }
    }
    return true;
};

export default bbsFormValidator;
