import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as BFTNet from 'api/BFTFetch';

import URL from 'constants/url';
import CODE from 'constants/code';
import { getLocalItem, setLocalItem, setSessionItem } from 'utils/storage';

function BFTLoginContent(props) {
    console.group("BFTLoginContent");
    console.log("[Start] BFTLoginContent ------------------------------");
    console.log("BFTLoginContent [props] : ", props);

    const navigate = useNavigate();
    const location = useLocation();
    console.log("BFTLoginContent [location] : ", location);

    const [userInfo, setUserInfo] = useState({ id: '', password: 'default', userSe: 'USR' });
	// eslint-disable-next-line no-unused-vars
    const [loginVO, setLoginVO] = useState({});

    const [saveIDFlag, setSaveIDFlag] = useState(false);

    const checkRef = useRef();

    const KEY_ID = "KEY_ID";
    const KEY_SAVE_ID_FLAG = "KEY_SAVE_ID_FLAG";

    const handleSaveIDFlag = () => {
        setLocalItem(KEY_SAVE_ID_FLAG, !saveIDFlag)
        setSaveIDFlag(!saveIDFlag);
    };

    useEffect(() => {
        let idFlag = getLocalItem(KEY_SAVE_ID_FLAG);
        if (idFlag === null) {
            setSaveIDFlag(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
            idFlag = false;
        } else {
            setSaveIDFlag(idFlag);
        }
        if (idFlag === false) {
            setLocalItem(KEY_ID, "");
            checkRef.current.className = "f_chk"
        } else {
            checkRef.current.className = "f_chk on"
        };
      }, []);

    useEffect(() => {
        let data = getLocalItem(KEY_ID);
        if (data !== null) {
            setUserInfo({ id: data, password: 'default', userSe: 'USR' });
        }
    }, []);

    const submitFormHandler = (e) => {
        console.log("BFTLoginContent submitFormHandler()");
        
        const loginUrl = "/api/token/";

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: userInfo.id, // 'id' 대신 'username' 필드 사용
                password: userInfo.password
            })
        };
        

        BFTNet.requestFetch(loginUrl,
            requestOptions,
            (resp) => {
                let accessToken = resp.access; // 접근 토큰
                let refreshToken = resp.refresh; // 갱신 토큰

                if (accessToken) {
                    setSessionItem('accessToken', accessToken);
                    setSessionItem('refreshToken', refreshToken);
                    setSessionItem('userInfo', JSON.stringify(resp.userInfo)); // 사용자 정보 저장
                    navigate(URL.MAIN);
                }
                else {
                    alert(resp)
                }
            })
    }

    console.log("------------------------------BFTLoginContent [End]");
    console.groupEnd("BFTLoginContent");
    
    return (
        <div className="contents" id="contents">
            {/* <!-- 본문 --> */}
            <div className="Plogin">
                <h1>로그인</h1>
                <p className="txt"></p>

                <div className="login_box">
                    <form name="" method="" action="" >
                        <fieldset>
                            <legend>로그인</legend>
                            <span className="group">
                                <input type="text" name="" title="아이디" placeholder="아이디" value={userInfo?.id}
                                    onChange={e => setUserInfo({ ...userInfo, id: e.target.value })} />
                                <input type="password" name="" title="비밀번호" placeholder="비밀번호"
                                    onChange={e => setUserInfo({ ...userInfo, password: e.target.value })} />
                            </span>
                            <div className="chk">
                                <label className="f_chk" htmlFor="saveid" ref={checkRef}>
                                    <input type="checkbox" name="" id="saveid" onChange={handleSaveIDFlag} checked={saveIDFlag}/> <em>ID저장</em>
                                </label>
                            </div>
                            <button type="button" onClick={submitFormHandler}><span>LOGIN</span></button>
                        </fieldset>
                    </form>
                </div>

                <ul className="list">
                    <li>비밀번호는 6~12자의 영문 대/소문자, 숫자, 특수문자를 혼합해서 사용하실 수 있습니다.</li>
                    <li>쉬운 비밀번호나 자주 쓰는 사이트의 비밀번호가 같을 경우, 도용되기 쉬우므로 주기적으로
                        변경하셔서 사용하는 것이 좋습니다.</li>
                </ul>
            </div>
            {/* <!--// 본문 --> */}
        </div>
    );
}

export default BFTLoginContent;