import React, { useState, useEffect } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import * as BFTNet from 'api/BFTFetch';
import URL from 'constants/url';
import CODE from 'constants/code';

import { default as BFTLeftNavSupport } from 'components/leftmenu/BFTLeftNavSupport';
import BFTAttachFile from 'components/BFTAttachFile';
import { getSessionItem } from 'utils/storage';
import BFTImageActivity from 'components/BFTImageActivity';
function BFTDownloadDetail(props) {
    console.group("BFTDownloadDetail");
    console.log("------------------------------");
    console.log("BFTDownloadDetail [props] : ", props);

    const navigate = useNavigate();
    const location = useLocation();
    console.log("BFTDownloadDetail [location] : ", location);

    const sessionUser = JSON.parse(getSessionItem('userInfo'));
    const IsSessionUserStaff = sessionUser?.is_staff;

    const searchCondition = location.state?.searchCondition;
    const downloadId = location.state?.downloadId;

    const [boardDetail, setBoardDetail] = useState({});
    const [boardAttachFiles, setBoardAttachFiles] = useState();

    const retrieveDetail = () => {
        const retrieveDetailURL = `/download/${downloadId}/`;
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-type': 'application/json'
            }
        }
        BFTNet.requestFetch(retrieveDetailURL,
            requestOptions,
            function (resp) {
                setBoardDetail(resp);
                setBoardAttachFiles(resp.resultFiles);
            }
        );
    }

    const onClickDeleteBoardArticle = (downloadId) => {
        const deleteBoardURL = `/download/${downloadId}/`;
    
        const requestOptions = {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
            }
        };
    
        BFTNet.requestFetch(deleteBoardURL, requestOptions,
            (resp) => {
                // Assuming that a successful deletion returns HTTP 204 No Content,
                // and that your modified requestFetch handles this correctly,
                // you can check for a successful deletion like this.
                console.log("Board deleted successfully", resp);
                alert("게시글이 삭제되었습니다.");
                navigate(URL.SUPPORT_DOWNLOAD, { replace: true });
            },
            (error) => {
                // Error handling
                console.error("Error deleting board:", error);
                alert("게시글 삭제 중 오류가 발생했습니다.");
                navigate({ pathname: URL.ERROR }, { state: { msg: error.toString() } });
            }
        );
    };

    useEffect(function () {
        retrieveDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    console.groupEnd("BFTDownloadDetail");

    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home">Home</Link></li>
                        <li><Link to={URL.NEWS}>교육문의</Link></li>
                        <li>자료실</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavSupport></BFTLeftNavSupport>
                    {/* <!--// Navigation --> */}

                    <div className="contents NOTICE_VIEW" id="contents">
                        {/* <!-- 본문 --> */}

                        <div className="top_tit">
                            <h1 className="tit_1">교육문의</h1>
                        </div>

                        <h2 className="tit_2">자료실</h2>

                        {/* <!-- 게시판 상세보기 --> */}
                        <div className="board_view">
                            <div className="board_view_top">
                                <div className="tit">{boardDetail.title}</div>
                                <div className="info">
                                    <dl>
                                        <dt>작성일</dt>
                                        <dd>{new Date(boardDetail.created_at).toLocaleDateString('ko-KR', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                            })}
                                        </dd>
                                        <dt>수정일</dt>
                                        <dd>{new Date(boardDetail.updated_at).toLocaleDateString('ko-KR', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                            })}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            
                            <BFTImageActivity boardFiles={boardAttachFiles} />

                            <div className="board_article">
                                <textarea name="" cols="30" rows="10" readOnly="readonly" defaultValue={boardDetail && boardDetail.content}></textarea>
                            </div>
                            
                            {boardAttachFiles != '' && <div className="board_attach">
                                {/* {boardAttachFiles && <BFTAttachFile boardFiles={boardAttachFiles} />} */}
                                {boardAttachFiles && <BFTAttachFile boardFiles={boardAttachFiles} />}
                            </div>}


                            <div className="board_btn_area">
                                {IsSessionUserStaff &&
                                    <div className="left_col btn3">
                                        <Link to={{pathname: URL.SUPPORT_DOWNLOAD_MODIFY}}
                                            state={{
                                                downloadId: boardDetail.id
                                            }}
                                            className="btn btn_skyblue_h46 w_100">수정</Link>
                                        <button className="btn btn_skyblue_h46 w_100" onClick={(e) => {
                                            e.preventDefault();
                                            onClickDeleteBoardArticle(boardDetail.id);
                                        }}>삭제</button>
                                    </div>
                                }

                                <div className="right_col btn1">
                                    <Link to={{pathname: URL.SUPPORT_DOWNLOAD}}
                                        state={{
                                            searchCondition: searchCondition
                                        }}
                                        className="btn btn_blue_h46 w_100">목록</Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- 게시판 상세보기 --> */}

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BFTDownloadDetail;