import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function BFTLeftNavNews() { 
    console.groupCollapsed("BFTLeftNavNews");
    console.log("[Start] BFTLeftNavNews ------------------------------");
    console.log("------------------------------BFTLeftNavNews [End]");
    console.groupEnd("BFTLeftNavNews");
    return (
        <div className="nav">
            <div className="inner">
                <h2>소식</h2>
                <ul className="menu4">
                    <li><NavLink to={URL.NEWS_SCHEDULE} className={({ isActive }) => (isActive ? "cur" : "")}>주요일정</NavLink></li>
                    <li><NavLink to={URL.NEWS_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>공지사항</NavLink></li>
                    <li><NavLink to={URL.NEWS_ACTIVITY} className={({ isActive }) => (isActive ? "cur" : "")}>교육활동</NavLink></li>
                </ul>
            </div>
        </div>
    );
}

export default React.memo(BFTLeftNavNews);