import React from 'react';
import { Link } from 'react-router-dom';

function BFTFooter() {
    return (
        <div className="footer">
            <div className="inner">
                <h1>
                    <Link to="">
                        <img className="w" src="/assets/images/logo_footer_w2.png" alt="" />
                        <img className="m" src="/assets/images/logo_footer_m2.png" alt="" />
                    </Link>
                </h1>
                <div className="info">
                    <p>
                        대표문의메일 : bftlab@bftlab.co.kr  <span className="m_hide">|</span><br className="m_show" />  교육문의 : 010-7714-3281<br />
                    </p>
                    <p className="copy">Copyright © 2024 비에프티랩 코딩학원. All Rights Reserved.</p>
                </div>
                <div className="right_col">
                    <Link to="https://open.kakao.com/o/s2EJILwg">
                        <img className="w" src="/assets/images/bftlab_kakao.png" alt="" />
                        <img className="m" src="/assets/images/bftlab_kakao.png" alt="" />
                    </Link>
                    <Link to="https://www.instagram.com/bftlab?igsh=Y3V4bXk2emMzcWpi">
                        <img className="w" src="/assets/images/bftlab_insta.png" alt="" />
                        <img className="m" src="/assets/images/bftlab_insta.png" alt="" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default BFTFooter;