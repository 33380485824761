import React from 'react';
import { Link } from 'react-router-dom';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';
import URL from 'constants/url';

function BFTCurriculumLevel2() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><Link className="home" to={URL.MAIN}>Home</Link></li>
                    <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                    <li>중급 레벨</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">커리큘럼</h1>

                    <p className="txt_1"></p>
                    
                    <h2 className="tit_4">중급 레벨</h2>


                    <h3 className="tit_5">컴퓨터 일반</h3>

                    <div className="msg">
                        <p>기초를 바탕으로 보다 심화된 소프트웨어와 하드웨어 활용 기술을 익히고, 다양한 문제 해결 사례를 통해 실무 능력을 강화합니다. 네트워크 설정, 데이터 관리 등 중급 수준의 IT 기술을 습득하여 효율적으로 업무를 수행할 수 있도록 합니다.</p>
                        <ul className="curri">
                            <li><strong>알고리즘: </strong>알고리즘 개념 이해, 정렬 및 검색 알고리즘 구현 능력 배양, 문제 해결과 논리적 사고 능력 향상을 통해 다양한 문제를 효율적으로 해결합니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">프로그래밍 언어</h3>

                    <div className="msg">
                        <p>프로그래밍 언어의 심화된 구문과 개념을 익히고, 복잡한 알고리즘과 자료 구조를 활용하여 다양한 문제를 해결하는 능력을 강화합니다. 객체 지향 프로그래밍, 데이터베이스 연동, 파일 입출력 등을 통해 실무 능력을 배양하며, 협업 및 문서화 기술을 습득하여 팀 프로젝트를 효과적으로 수행합니다.</p>
                        <ul className="curri">
                            <li><strong>파이썬응용: </strong>파이썬을 활용해 데이터 분석, 웹 개발, 인공지능 등 다양한 응용 프로그램을 개발하여 실무 능력을 배양합니다.</li>
                            <li><strong>C/C++: </strong>C언어의 기본 문법과 개념, 포인터와 메모리 관리 등을 익히며 프로그램 구현과 디버깅 능력을 향상시킵니다. 객체지향 프로그래밍과 클래스, 상속 등을 이해하고, 효율적인 데이터 구조와 알고리즘을 활용해 복잡한 소프트웨어를 개발합니다.</li>
                            <li><strong>자바: </strong>자바의 기본 문법과 객체지향 프로그래밍을 이해하고, 웹 애플리케이션과 안드로이드 앱 등 다양한 애플리케이션을 개발합니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">웹</h3>

                    <div className="msg">
                        <p>HTML, CSS, JavaScript를 심화 학습하여 보다 복잡하고 세련된 웹 페이지를 구축하는 능력을 기릅니다. 웹 폼, 이벤트 처리, API 통신 등을 통해 사용자와 상호작용하는 기능을 구현하며, 서버와의 기본적인 상호작용과 데이터베이스 연동을 통해 동적인 웹 애플리케이션을 개발합니다.</p>
                        <ul className="curri">
                            <li><strong>자바스크립트(React, vue.js 등): </strong>자바스크립트의 기본 문법을 이해하고, 이벤트 처리, DOM 조작, AJAX 등을 활용해 동적이고 상호작용하는 웹 애플리케이션을 개발하는 능력을 배양합니다.</li>
                        </ul>
                    </div>                    

                    <h3 className="tit_5">앱</h3>

                    <div className="msg">
                        <p>Android 또는 iOS 플랫폼에서 중급 수준의 앱을 개발하는 능력을 기릅니다. 사용자 경험(UX)과 인터페이스(UI) 디자인을 고려하여 더 복잡한 앱을 구축하며, 데이터 저장소와 API 연동 등을 통해 동적인 기능을 구현합니다. 플랫폼별 권장 사항과 베스트 프랙티스를 익혀 앱의 성능과 안정성을 높입니다.</p>
                        <ul className="curri">
                            <li><strong>코틀린: </strong>코틀린의 기본 문법을 이해하고, 안드로이드 앱 개발에 활용하며, 안정적이고 효율적인 모바일 앱을 설계하고 개발하는 능력을 배양합니다.</li>
                            <li><strong>스위프트: </strong>스위프트의 기본 문법을 이해하고, iOS 및 macOS 앱을 개발하며, 고급 기능과 iOS 생태계 이해를 통해 실무 경험을 쌓는 것을 목표로 합니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">빅데이터 분석</h3>

                    <div className="msg">
                        <p>대규모 데이터를 효율적으로 처리하고 분석하는 능력을 기릅니다. 다양한 데이터 마이닝 기법과 고급 시각화 기술을 익히고, 분산 시스템(Hadoop, Spark 등)을 활용하여 데이터를 처리하는 방법을 학습합니다. 머신러닝 알고리즘을 활용하여 실질적인 데이터 문제를 해결하는 능력을 배양합니다.</p>
                        <ul className="curri">
                            <li><strong>빅데이터분석(분류/군집/회귀/시계열/연관성): </strong>대규모 데이터를 수집, 정제, 분석하여 인사이트를 도출하며, 데이터 분석 도구와 기술을 활용해 통계적 및 시각적 분석 능력을 배양합니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">인공지능</h3>

                    <div className="msg">
                        <p>머신러닝 및 딥러닝 기술을 심화 학습하여 다양한 문제를 해결하는 능력을 배양합니다. 실제 데이터에 적용하여 모델을 구축하고 평가하는 경험을 쌓으며, 주요 알고리즘의 원리를 이해하고, 하이퍼파라미터 튜닝과 교차 검증 등의 기법을 활용하여 모델 성능을 최적화합니다.</p>
                        <ul className="curri">
                            <li><strong>영상처리(opencV): </strong>영상 데이터를 처리하고 분석하는 기본 이론과 기술을 익히며, 다양한 알고리즘을 활용해 영상을 개선하고 정보를 추출하는 능력을 배양합니다.</li>
                            <li><strong>딥러닝기초: </strong>딥러닝의 기본 개념과 구조를 이해하고, 신경망 학습 알고리즘과 활성화 함수를 배우며, 다양한 모델을 구현하고 데이터를 학습시키는 능력을 배양합니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">포트폴리오</h3>

                    <div className="msg">
                        <p>학생들이 중급 수준의 프로젝트를 통해 자신의 실무 능력을 체계적으로 정리하고, 전문적인 포트폴리오를 구축하는 것을 목표로 합니다. 프로젝트 기획, 실행, 평가의 전 과정을 심화 학습하며, 팀원과의 협업을 통해 실제 업무 환경에서 필요한 다양한 역량을 강화합니다. 이 과정에서 프로젝트 문서화, 발표, 코드 관리 등의 기술을 습득합니다.</p>
                        <ul className="curri">
                            <li><strong>공모전: </strong>창의적 아이디어 발굴과 협업을 통해 프로젝트를 기획하고 실행하며, 문제 해결과 협업 능력을 향상시켜 실전 경험을 쌓습니다.</li>
                            <li><strong>정보올림피아드: </strong>정보올림피아드 참여 경험과 성과를 정리하여, 알고리즘 문제 해결 능력과 프로그래밍 실력을 보여주는 자료집입니다. 대회 준비 과정, 문제 풀이 방법, 프로젝트 성과 등을 포함해 학습과 성장 과정을 체계적으로 정리합니다.</li>
                            <li><strong>실전 프로젝트: </strong>실제 IT 프로젝트를 기획, 실행, 완료하는 과정을 학습하여 프로젝트 관리, 소프트웨어 개발, 품질 관리 등 실무 역량을 향상시킵니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">자격증</h3>

                    <div className="msg">
                        <p>학생들이 중급 수준의 컴퓨터 기술과 소프트웨어 활용 능력을 향상시키는 것을 목표로 합니다. 정보처리기능사 및 산업기사 자격증 대비를 통해 심화된 문제 해결 및 정보 처리 기술을 습득합니다. 이를 통해 업무와 학업에서 효율적으로 컴퓨터 기술을 활용할 수 있도록 합니다.</p>
                        <ul className="curri">
                            <li><strong>정보처리기능사/산업기사: </strong>데이터베이스, 네트워크, 시스템 운영 등 정보처리 분야의 전문 지식을 습득하고, 문제 해결 및 프로젝트 관리 능력을 향상시킵니다.</li>
                        </ul>
                    </div>

                    {/* <img className="w" src="/assets/images/level2.png" alt=""/> */}

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default BFTCurriculumLevel2;