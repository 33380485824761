import React from 'react';
import { Link } from 'react-router-dom';

import { default as BFTLeftNavAbout } from 'components/leftmenu/BFTLeftNavAbout';

function BFTAboutSite() {
    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to="" className="home">Home</Link></li>
                        <li><Link to="">학원소개</Link></li>
                        <li>소개</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavAbout></BFTLeftNavAbout>
                    {/* <!--// Navigation --> */}

                    <div className="contents SITE_INTRO" id="contents">
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">학원 소개</h1>
                        <br />
                        {/* <p className="txt_1">BFTLab. AI 코딩 아카데미</p> */}
                        
                        <h2 className="tit_4">미래를 선도하는 IT 교육의 중심</h2>
                        <br />
                        <h3 className="tit_5">비에프티랩 코딩학원</h3>

                        <p className="msg_1">
                            비에프티랩 코딩학원은 혁신적인 IT 교육으로 미래의 리더를 양성하는 것을 목표로 합니다. 저희 학원은 단순한 기술 전달을 넘어, 문제 해결력과 창의력을 갖춘 인재를 키우는 데 집중하고 있습니다.<br /><br />
                            학생들에게 실질적인 프로젝트 경험을 제공하며, 이를 통해 상상했던 아이디어를 현실로 구현하는 능력을 배양합니다. 우리는 학생들이 글로벌 IT 기업에서 선두주자가 될 수 있도록 지원합니다.<br /><br />
                            저희 학원은 알고리즘과 코딩 교육을 통해, 학생들이 정보올림피아드 등 각종 경시대회에서 우수한 성적을 거두는 것은 물론, 대학 입시와 취업 시장에서도 경쟁력을 갖출 수 있도록 돕고 있습니다.<br /><br />
                            비에프티랩 코딩학원은 IT 교육을 통해 더 나은 미래를 만들어가는 것을 신념으로 삼고 있습니다. 여러분의 꿈을 현실로 만들 준비가 되셨나요? 지금 바로 저희와 함께 시작해 보세요.
                        </p>
                    
                        <div className="ds_1">

                            <h4 className="t_1">우리가 추구하는 가치</h4>
                            
                            <ul className="li_1">
                                <li>창의적 문제 해결<br/>능력 함양</li>
                                <li>실용적 프로젝트 <br/> 기반 학습</li>
                                <li>글로벌 IT <br/>트렌드 이해</li>
                            </ul>
                            
                            <p className="t_2">미래 IT 인재 양성을 위한<br/>최적의 환경</p>

                            <div className="bot">
                                <h4 className="t_3">비에프티랩의 교육 방식</h4>
                                <ul>
                                    <li><span>실무 중심의 프로젝트 기반 학습으로 
                                        코딩 능력 및 협업 <br /> 스킬 강화</span></li>
                                    <li><span>다양한 대회 참여를 통한<br/>
                                        경쟁력 있는 포트폴리오 구축</span></li>
                                    <li><span>교수 및 박사급 강사진에 의한 맞춤형 멘토링으로<br/>
                                        학생 개개인의 성장 지원</span></li>
                                </ul>
                                <p className="t_4">비에프티랩 코딩학원은 학생들이 실제 IT 업계에서 필요로 하는 기술과 역량을 갖출 수 있도록, 현장감 있는 교육과정과 다양한 실습 기회를 제공합니다. 우리와 함께 미래의 IT 전문가로 성장하세요.</p>
                            </div>
                        </div>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BFTAboutSite;