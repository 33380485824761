import React from 'react';
import { Link } from 'react-router-dom';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';
import URL from 'constants/url';

function BFTCurriculumLevel3() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><Link className="home" to={URL.MAIN}>Home</Link></li>
                    <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                    <li>고급 레벨</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">커리큘럼</h1>

                    <p className="txt_1"></p>
                    
                    <h2 className="tit_4">고급 레벨</h2>                   

                    <h3 className="tit_5">컴퓨터 일반</h3>

                    <div className="msg">
                        <p>고급 IT 기술을 통해 복잡한 문제를 해결하는 능력을 기릅니다. 최신 소프트웨어와 하드웨어 기술을 익히고, 시스템 통합, 보안 관리, 고급 네트워크 설정 등 고난도의 실무 기술을 배양합니다. 지속적인 학습과 자기 계발을 통해 변화하는 IT 환경에 적응하고 선도할 수 있는 능력을 갖춥니다.</p>
                        <ul className="curri">
                            <li><strong>운영체제: </strong>운영체제 역할과 기능 이해, 프로세스 및 메모리 관리 등 핵심 개념 숙지, 파일 시스템과 보안 관리 능력 강화로 컴퓨터 시스템을 효율적으로 관리합니다.</li>
                            <li><strong>데이터베이스: </strong>데이터베이스 개념과 기본 원리 이해, 데이터 모델링과 SQL 활용 능력 배양, 데이터베이스 설계 및 관리 실무 능력 강화가 목표입니다.</li>
                            <li><strong>네트워크: </strong>네트워크 개념과 원리 이해, OSI 모델과 IP 주소 체계 활용 능력 배양, 네트워크 보안과 프로토콜 이해, 네트워크 장비 설정 및 유지보수 실무 능력 강화가 목표입니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">프로그래밍 언어</h3>

                    <div className="msg">
                        <p>고급 프로그래밍 기술과 디자인 패턴을 통해 복잡한 소프트웨어를 설계하고 구현하는 능력을 기릅니다. 성능 최적화, 보안 코딩, 대규모 소프트웨어 아키텍처 설계 등 고난도 기술을 익히며, 최신 개발 도구와 협업 플랫폼을 활용하여 효율적인 개발 환경을 구축합니다. 또한, 코드 리뷰와 테스트 자동화 등의 기술을 통해 소프트웨어 품질을 향상시키는 능력을 배양합니다.</p>
                        <ul className="curri">
                            <li><strong>게임 프로젝트: </strong>게임 개발 과정을 이해하고, 게임 엔진과 도구를 활용해 게임을 디자인하며, 프로젝트 관리 및 협업 능력을 향상시킵니다.</li>
                            <li><strong>응용 프로젝트: </strong>실무 환경에서 응용 프로그램을 개발하고 배포하며, 프로젝트 관리와 협업 능력을 강화하고 문제 해결 능력을 기릅니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">웹</h3>

                    <div className="msg">
                        <p>고급 웹 개발 기술을 통해 복잡한 웹 애플리케이션을 설계하고 구현하는 능력을 배양합니다. 최신 프레임워크와 라이브러리를 활용하여 효율적인 개발을 수행하며, 서버사이드 프로그래밍과 데이터베이스 관리, RESTful API 설계 및 보안 등을 학습합니다. 또한, 성능 최적화와 웹 애플리케이션 배포 등의 고난도 기술을 익혀 실무 능력을 강화합니다.</p>
                        <ul className="curri">
                            <li><strong>웹프레임워크 (스프링/장고): </strong>스프링 프레임워크의 기본 개념과 구성 요소를 이해하고, 이를 활용해 안정적이고 확장 가능한 웹 애플리케이션을 개발하는 실무 능력을 배양합니다. 장고 프레임워크의 기본 구조와 기능을 이해하고, 데이터베이스 모델링, URL 설정, 템플릿 디자인 등을 통해 확장 가능한 웹 애플리케이션을 개발하는 능력을 배양합니다.</li>
                            <li><strong>웹 프로젝트: </strong>웹 개발의 전체 프로세스를 이해하고, 팀 협업을 통해 실제 웹 프로젝트를 기획, 디자인, 개발하며, 프로젝트 관리와 문제 해결 능력을 강화합니다.</li>
                        </ul>
                    </div>                    

                    <h3 className="tit_5">앱</h3>

                    <div className="msg">
                        <p>고급 모바일 애플리케이션 개발 기술을 통해 복잡하고 기능이 풍부한 앱을 설계하고 구현하는 능력을 배양합니다. 고급 UI/UX 디자인 기법, 애니메이션 및 트랜지션, 네이티브 모듈 통합 등의 기술을 익히며, 성능 최적화와 보안 강화 방안을 적용합니다. 또한, 앱 마켓 출시, 유지보수, 데이터 분석 및 A/B 테스트 등을 통해 앱 생태계를 이해하고 향후 발전 가능성을 탐구합니다.</p>
                        <ul className="curri">
                            <li><strong>앱 프로젝트: </strong>모바일 앱 개발 프로세스를 이해하고, Android 또는 iOS 플랫폼에 맞춰 앱을 기획하고 개발하며, 협업을 통해 완성도 높은 앱을 제작하는 경험을 제공합니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">빅데이터 분석</h3>

                    <div className="msg">
                        <p>고급 빅데이터 처리 및 분석 기술을 통해 복잡한 데이터 문제를 해결하는 능력을 배양합니다. 고급 분산 시스템과 클라우드 컴퓨팅 환경에서 빅데이터를 처리하고, 심화된 머신러닝 알고리즘을 적용하여 예측 모델과 추천 시스템을 개발합니다. 또한, 실시간 데이터 처리와 대규모 데이터 시각화 기술을 활용하여 비즈니스 인사이트를 도출하고, 데이터 기반의 전략적 의사결정을 지원합니다.</p>
                        <ul className="curri">
                            <li><strong>빅데이터 분석 프로젝트: </strong>실제 빅데이터 프로젝트를 통해 데이터 수집, 전처리, 분석, 시각화를 체계적으로 수행하고, 협업을 통해 문제 해결 능력을 향상시킵니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">인공지능</h3>

                    <div className="msg">
                        <p>고급 인공지능 이론과 최신 알고리즘을 학습하여 복잡한 문제를 해결하는 능력을 배양합니다. 대규모 데이터셋을 다루고, 심화된 딥러닝 기술을 적용하여 정교한 모델을 구축합니다. 실제 프로젝트를 통해 모델을 실무 환경에 적용하고, 모델의 효과를 평가 및 개선하는 능력을 강화하며, 최신 연구 동향을 반영한 고급 AI 기술을 익혀 실무 능력을 극대화합니다.</p>
                        <ul className="curri">
                            <li><strong>딥러닝프레임워크(텐서플로우/파이토치): </strong>딥러닝 및 머신러닝 모델을 구현하고 학습시키는 텐서플로우의 기본 개념과 사용법을 익히며, 실전 프로젝트를 통해 모델 구축과 평가 실무 능력을 향상시킵니다. 딥러닝 및 머신러닝 모델 구현과 학습에 사용되는 파이토치의 기본 개념과 사용법을 이해하고, 실제 데이터로 모델을 구축하고 평가하는 실무 능력을 배양합니다.</li>
                            <li><strong>딥러닝 프로젝트: </strong>딥러닝 이론과 기술을 활용해 실제 데이터를 다루는 프로젝트를 기획하고 실행하며, 데이터 전처리부터 모델 학습, 평가까지 모든 과정을 경험하고 협업 능력을 기릅니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">포트폴리오</h3>

                    <div className="msg">
                        <p>학생들이 고급 수준의 프로젝트를 통해 실무 능력을 최적화하고, 취업 또는 진학에 도움이 되는 전문적인 포트폴리오를 완성하는 것을 목표로 합니다. 복잡한 프로젝트를 기획, 실행, 평가하며, 팀 리더십과 협업 능력을 배양합니다. 실제 업무 환경에서 필요한 고급 기술과 문제 해결 능력을 강화하고, 프로젝트 결과를 효과적으로 발표하고 문서화하는 능력을 개발합니다.</p>
                        <ul className="curri">
                            <li><strong>코딩캠프: </strong>프로그래밍 기본 원리와 개발 환경을 소개하고, 실전 프로젝트로 문제 해결 능력과 협업 능력을 향상시키며 실무적 프로그래밍 역량을 배양합니다.</li>
                            <li><strong>논문지도: </strong>학술 연구 방법론을 이해하고, 논문 주제 설정, 구조 작성, 표준 양식 포맷팅 등을 통해 학문적 표현력과 논리적 논증 능력을 배양합니다.</li>
                            <li><strong>특허지도: </strong>특허 출원의 기본 개념과 절차를 이해하고, 아이디어를 법적으로 보호하는 방법을 학습하여 실무적 특허 출원 역량을 배양합니다.</li>
                        </ul>
                    </div>    

                    <h3 className="tit_5">자격증</h3>

                    <div className="msg">
                        <p>학생들이 고급 수준의 컴퓨터 기술과 소프트웨어 활용 능력을 극대화하여 실무 경쟁력을 강화하는 것을 목표로 합니다. 정보처리기사 등 고난이도 자격증을 준비하며, 복잡한 문제 해결과 정보 처리 기술을 익힙니다. 고급 데이터베이스 관리, 프로그래밍, 시스템 관리 등 다양한 고급 소프트웨어 기술을 학습하고, 실무에서 효과적으로 적용할 수 있는 능력을 배양합니다.</p>
                        <ul className="curri">
                            <li><strong>정보처리기사: </strong>데이터베이스, 네트워크, 시스템 운영 등의 정보처리 기술을 습득해 실무 역량을 강화하고, 문제 해결 및 프로젝트 관리 능력을 배양합니다.</li>
                        </ul>
                    </div>

                    {/* <img className="w" src="/assets/images/level3.png" alt=""/> */}

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default BFTCurriculumLevel3;

