
const URL = {
    //COMMON
    MAIN                        : "/", // 메인페이지   
    
    LOGIN                       : "/login", // 로그인
    ERROR                       : "/error", // 로그인
    
    //ABOUT
    ABOUT                       : "/about", // 학원소개   
    ABOUT_BFT                   : "/about/bft", // 학원소개/소개
    ABOUT_TEACHER               : "/about/teacher", // 학원소개/강사소개
    ABOUT_LOCATION              : "/about/location", // 학원소개/찾아오시는길
    
    //CURRICULUM
    CURRICULUM                  : "/curriculum", // 커리큘럼
    CURRICULUM_TOTAL            : "/curriculum/total", // 커리큘럼/전체커리큘럼
    CURRICULUM_LEVEL1           : "/curriculum/level1", // 커리큘럼/초등학생
    CURRICULUM_LEVEL2           : "/curriculum/level2", // 커리큘럼/중학생
    CURRICULUM_LEVEL3           : "/curriculum/level3", // 커리큘럼/고등학생/일반
    CURRICULUM_CERTIFICATE      : "/curriculum/certificate", // 커리큘럼/자격증
    CURRICULUM_PORTFOLIO        : "/curriculum/portfolio", // 커리큘럼/자격증
    
    //SUPPORT
    SUPPORT                     : "/support", // 교육문의
    SUPPORT_DOWNLOAD            : "/support/download", // 교육문의/자료실
    SUPPORT_DOWNLOAD_DETAIL     : "/support/download/detail", // 교육문의/자료실/상세
    SUPPORT_DOWNLOAD_CREATE     : "/support/download/create", // 교육문의/자료실/등록
    SUPPORT_DOWNLOAD_MODIFY     : "/support/download/modify", // 교육문의/자료실/등록
    SUPPORT_QNA                 : "/support/qna", // 교육문의/묻고답하기
    SUPPORT_QNA_CREATE          : "/support/qna/create", // 소식/공지사항등록
    SUPPORT_QNA_MODIFY          : "/support/qna/modify", // 소식/공지사항수정
    SUPPORT_QNA_DETAIL          : "/support/qna/detail", // 교육문의/묻고답하기/상세
    SUPPORT_CONSULT             : "/support/consult", // 교육문의/상담신청
    
    //NEWS
    NEWS                        : "/news", // 소식 
    NEWS_DAILY                  : "/news/daily", // 소식/오늘의행사
    NEWS_DAILY_DETAIL           : "/news/daily/detail", // 소식/오늘의행사상세
    NEWS_SCHEDULE               : "/news/schedule", // 소식/금주의행사
    NEWS_SCHEDULE_DETAIL        : "/news/schedule/detail", // 소식/금주의행사상세
    NEWS_SCHEDULE_CREATE        : "/news/schedule/create", // 소식/금주의행사상세
    NEWS_SCHEDULE_MODIFY        : "/news/schedule/modify", // 소식/금주의행사상세
    NEWS_NOTICE                 : "/news/notice", // 소식/공지사항
    NEWS_NOTICE_DETAIL          : "/news/notice/detail", // 소식/공지사항상세
    NEWS_NOTICE_CREATE          : "/news/notice/create", // 소식/공지사항등록
    NEWS_NOTICE_MODIFY          : "/news/notice/modify", // 소식/공지사항수정
    NEWS_NOTICE_REPLY           : "/news/notice/reply", // 소식/공지사항답글
    NEWS_ACTIVITY               : "/news/activity", // 소식/교육활동
    NEWS_ACTIVITY_DETAIL        : "/news/activity/detail", // 소식/교육활동상세
    NEWS_ACTIVITY_CREATE        : "/news/activity/create", // 소식/교육활동등록
    NEWS_ACTIVITY_MODIFY        : "/news/activity/modify", // 소식/교육활동수정
    NEWS_ACTIVITY_REPLY         : "/news/activity/reply", // 소식/교육활동답글
    

}

export default URL;