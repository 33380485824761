import React from 'react';
import { Link } from 'react-router-dom';
import URL from 'constants/url';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';

function BFTCurriculumTotal() {
    return (
        <div className="container">
            <div className="c_wrap">
                {/* <!-- Location --> */}
                <div className="location">
                    <ul>
                        <li><Link to={URL.MAIN} className="home">Home</Link></li>
                        <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                        <li>전체 교육과정</li>
                    </ul>
                </div>
                {/* <!--// Location --> */}

                <div className="layout">
                    {/* <!-- Navigation --> */}
                    <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                    {/* <!--// Navigation --> */}
                    
                    <div className="contents BUSINESS_INTRO" id="contents">
                        {/* <!-- 본문 --> */}

                        <h1 className="tit_3">커리큘럼</h1>

                        <p className="txt_1"></p>
                        
                        <h2 className="tit_4">전체 교육과정</h2>
                       
                        <h3 className="tit_5">과정 소개</h3>

                        <p className="msg_1">초급 레벨부터 고급 레벨까지 커리큘럼입니다. <br/>레벨별 과목 설명은 해당 레벨 페이지에서 확인해보세요.</p>

                        <p className="img">
                            {/* <img className="w" src="/assets/images/img_business_intro.png" alt=""/>
                            <img className="m" src="/assets/images/img_business_intro_m.png" alt=""/> */}
                            <img className="w" src="/assets/images/curri.png" alt=""/>
                            <img className="m" src="/assets/images/curri_m.png" alt=""/>
                        </p>

                        {/* <!--// 본문 --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BFTCurriculumTotal;