import React from 'react';
import { useNavigate } from 'react-router-dom';

import URL from 'constants/url';
import * as BFTNet from 'api/BFTFetch';
import { SERVER_URL } from 'config';
import CODE from 'constants/code';

function BFTAttachFile({ boardFiles, mode, fnChangeFile, fnDeleteFile }) {
    console.groupCollapsed("BFTAttachFile");

    const navigate = useNavigate();

    function onClickDownFile(fileUrl) {
        window.open(fileUrl);
    }

    function onClickDeleteFile(atchFileId, fileSn, fileIndex) {
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                atchFileId: atchFileId,
                fileSn: fileSn
            })
        };
        BFTNet.requestFetch(`/file/`,
            requestOptions,
            function (resp) {
                if (Number(resp.resultCode) === Number(CODE.RCV_SUCCESS)) {
                    const _deleteFile = boardFiles.splice(fileIndex, 1);
                    const _boardFiles = [...boardFiles];
                    fnDeleteFile(_boardFiles);
                    alert("첨부파일이 삭제되었습니다.");
                } else {
                    navigate({ pathname: URL.ERROR }, { state: { msg: resp.resultMessage } });
                }
            }
        );
    }

    function onChangeFileInput(e) {
        fnChangeFile(e.target.files);
    }

    let filesTag = [];
    if (boardFiles) {
        Object.values(boardFiles).forEach((item, index) => {
            filesTag.push(
                <React.Fragment key={index}>
                    <span>
                        <a href="#LINK" onClick={(e) => {
                            e.preventDefault();
                            onClickDownFile(item.fileUrl);
                        }} download>{item.orignlFileNm}</a>
                        {/* <a href={item.fileUrl}>{item.orignlFileNm}</a> */}
                        <span>[{item.fileMg}byte]</span>
                    </span>
                    {mode === CODE.MODE_MODIFY && (
                        <button className="btn btn_delete" onClick={() => onClickDeleteFile(item.atchFileId, item.fileSn, index)}></button>
                    )}
                    <br />
                </React.Fragment>
            );
        });
    }

    console.groupEnd("BFTAttachFile");

    return (
        <dl>
            <dt>첨부파일</dt>
            <dd>
                <span className="file_attach">
                    {filesTag}
                    {(mode === CODE.MODE_CREATE || mode === CODE.MODE_MODIFY) && (
                        <>
                            <input name="file_0" id="BFTComFileUploader" type="file" multiple onChange={onChangeFileInput}></input>
                        </>
                    )}
                </span>
            </dd>
        </dl>
    );
}

export default React.memo(BFTAttachFile);