import React from 'react';
import Slider from 'react-slick';

const BFTCarouselComponent = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    return (
        <div className="left_col">
            <Slider {...settings}>
                <div>
                    <img src="/assets/images/bftlab_main1.png" alt="BFTLab. 코딩학원 1" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main2.png" alt="BFTLab. 코딩학원 2" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main3.png" alt="BFTLab. 코딩학원 3" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main4.png" alt="BFTLab. 코딩학원 4" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main1.png" alt="BFTLab. 코딩학원 5" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main6.png" alt="BFTLab. 코딩학원 6" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main7.png" alt="BFTLab. 코딩학원 7" />
                </div>
                <div>
                    <img src="/assets/images/bftlab_main8.png" alt="BFTLab. 코딩학원 8" />
                </div>
            </Slider>
        </div>
    );
}

export default BFTCarouselComponent;
