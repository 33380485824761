import React from 'react';
import { Link } from 'react-router-dom';
import { default as BFTLeftNavCurriculum } from 'components/leftmenu/BFTLeftNavCurriculum';
import URL from 'constants/url';

function BFTCurriculumLevel1() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><Link className="home" to={URL.MAIN}>Home</Link></li>
                    <li><Link to={URL.CURRICULUM}>커리큘럼</Link></li>
                    <li>초급 레벨</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <BFTLeftNavCurriculum></BFTLeftNavCurriculum>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">커리큘럼</h1>

                    <p className="txt_1"></p>
                    
                    <h2 className="tit_4">초급 레벨</h2>

                    {/* <p className="img">
                        <img className="w" src="/assets/images/curri_level1.png" alt=""/>
                        <img className="m" src="/assets/images/curri_level1.png" alt=""/>
                    </p> */}

                    <h3 className="tit_5">컴퓨터 일반</h3>

                    <div className="msg">
                        <p>컴퓨터 기본 개념과 기초 실무 기술을 익히며, 문제 해결 능력을 기릅니다. 다양한 소프트웨어와 하드웨어의 기본적인 활용 방법을 배우고, 컴퓨터의 기초적인 운영과 관리 능력을 배양합니다.</p>
                        <ul className="curri">
                            <li><strong>하드웨어: </strong>컴퓨터 시스템 구성 요소와 동작 원리를 이해하고, 하드웨어 문제 진단 및 해결 능력, 조립 및 유지 관리 실무 능력을 배양합니다.</li>
                            <li><strong>윈도우: </strong>윈도우 운영체제 설치 및 설정, 사용자 계정과 파일 시스템 관리, 시스템 복구와 문제 해결 능력을 익히고, 효율적인 작업 실무 능력을 배양합니다.</li>
                            <li><strong>인터넷: </strong>인터넷 개념과 주요 프로토콜을 이해하고, 웹 브라우저 사용과 온라인 보안을 익히며, 정보 검색과 커뮤니케이션 능력을 배양합니다.</li>
                            <li><strong>한글과 컴퓨터: </strong>한글 워드 프로세서로 문서 작성, 편집, 서식 설정 능력을 익히고, 고급 기능과 협업 도구를 활용한 실무 능력을 배양합니다.</li>
                            <li><strong>파워포인트: </strong>프레젠테이션 슬라이드 작성 및 디자인, 멀티미디어 삽입, 애니메이션 효과 적용 능력을 키우고, 효과적인 발표 기술을 개발합니다.</li>
                            <li><strong>엑셀: </strong>데이터 입력과 관리, 기본 수식과 함수 활용, 차트와 그래프 작성 및 시각화 능력을 습득하여 데이터를 효과적으로 분석하고 전달합니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">프로그래밍 언어</h3>

                    <div className="msg">
                        <p>프로그래밍 언어의 기본 구문과 개념을 이해하고, 간단한 알고리즘과 자료 구조를 활용하여 문제를 해결하는 능력을 기릅니다. 기본적인 프로그램 작성, 디버깅, 테스트 방법을 배우고, 소프트웨어 개발의 기초 과정을 이해합니다.</p>
                        <ul className="curri">
                            <li><strong>스크래치: </strong>시각적 블록을 이용해 알고리즘을 구현하고 프로그램을 디자인하며, 문제 해결 능력과 창의적 사고를 기릅니다.</li>
                            <li><strong>엔트리: </strong>블록 코딩을 통해 프로그래밍 기초를 배우고, 창의적 프로젝트로 디지털 리터러시와 컴퓨터 과학 이해를 높입니다.</li>
                            <li><strong>파이썬기초: </strong>파이썬의 기본 문법과 데이터 타입을 배우고, 간단한 프로그램 작성과 실행으로 프로그래밍 흥미와 창의적 사고를 촉진합니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">웹</h3>

                    <div className="msg">
                        <p>웹 개발의 기초 개념을 이해하고, HTML과 CSS를 활용하여 웹 페이지를 디자인하고 구축하는 능력을 배양합니다. 기본적인 웹 구조와 스타일링 기법을 배우며, 간단한 JavaScript를 통해 웹 페이지에 인터랙티브 요소를 추가하는 방법을 익힙니다.</p>
                        <ul className="curri">
                            <li><strong>HTML: </strong>HTML의 기본 구조와 태그를 이해하고, 시멘틱 웹 마크업을 활용해 웹 페이지를 디자인하며, 웹 표준과 접근성을 준수하는 능력을 배양합니다.</li>
                            <li><strong>CSS: </strong>CSS의 기본 속성과 선택자를 익히고, 웹 페이지 스타일링과 반응형 웹 디자인을 통해 다양한 디바이스에 호환되는 웹 페이지를 구축하는 능력을 키웁니다.</li>
                        </ul>
                    </div>

                    <h3 className="tit_5">앱·빅데이터 분석·인공지능·포트폴리오</h3>

                    <div className="msg">
                        <p>초급/중급/고급 레벨의 컴퓨터 일반, 프로그래밍 언어, 웹 등 일부 선행 교과목 이수 필요</p>
                    </div>

                    <h3 className="tit_5">자격증</h3>

                    <div className="msg">
                        <p>학생들이 컴퓨터 및 소프트웨어의 기본 개념을 이해하고, 기초적인 컴퓨터 활용 능력을 배양하는 것을 목표로 합니다. 컴퓨터활용, 워드프로세서 등에서 간단한 문서 작성과 데이터 처리 방법을 익히며, 기본적인 문제 해결 능력을 기릅니다. 이 과정에서 기본적인 소프트웨어 사용법과 간단한 정보 처리 기술을 학습합니다.</p>
                        <ul className="curri">
                            <li><strong>컴퓨터활용능력: </strong>워드, 스프레드시트, 프레젠테이션 소프트웨어를 익혀 컴퓨터 기초 지식과 응용 능력을 배양하고, 업무 효율성을 높이는 기술을 습득합니다.</li>
                            <li><strong>워드프로세서: </strong>워드프로세서를 사용해 문서 작성, 편집 능력을 향상시키며, 서식, 그래픽, 표 삽입, 문서 보안 기능을 익혀 업무 효율성을 높입니다.</li>
                        </ul>
                    </div>                    

                    {/* <img className="w" src="/assets/images/level1.png" alt=""/> */}

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default BFTCurriculumLevel1;