import React from 'react';

function BFTImageActivity({ boardFiles }) {

    let filesTag = [];

    if (boardFiles) {
        Object.values(boardFiles).forEach(function (item, index) {
            // 파일 확장자가 이미지인지 확인하는 조건 추가
            const fileExtension = item.fileUrl.split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];

            if (imageExtensions.includes(fileExtension)) {
                filesTag.push(
                    <React.Fragment key={index}>
                        <img src={item.fileUrl} alt=""  /><br />
                    </React.Fragment>
                );
            }
        });
    }
    console.log("filesTag : ", filesTag);
    console.groupEnd("BFTAttachFile");

    return (
        <div className="board_attach_img">
            {filesTag}
        </div>
    );
}

export default BFTImageActivity;
